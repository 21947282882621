import '../css/index.scss';
import { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import {  Row, Col} from 'react-bootstrap';
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import { KvizContextBrPitanja } from '../../../globalne/KvizContextBrPitanja';
import { KvizContext } from '../../../globalne/KvizContext';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { socketContext } from '../../../globalne/Socket';

type Tipovi_podataka = {
  igrac: number;
  igrac2: number;
  kanal:any;
  id_meca: number;
}

function Pitanje(props:any) {  
    const socket = useContext(socketContext);  
    const konekcija = useContext(konekcijaContext);
    const [pitanje, setPitanje] = useState<any>();
    const {podaciKviz, setPodaciKviz} = useContext<any>(KvizContext);
    const dolaz_param = useLocation();
    const podaci_dolazni = dolaz_param.state as Tipovi_podataka;
    var id_meca = podaci_dolazni.id_meca; 
    var kanal = podaci_dolazni.kanal; 
    const {podaciKvizBrPitanja, setPodaciKvizBrPitanja} = useContext<any>(KvizContextBrPitanja);
    const [brPitanja, setBrPitanja] = useState<any>(podaciKvizBrPitanja[0].br_pitanja-1);  //posto je pitanje definisano kao broj jedan i tako ga i prikazujemo igracima ali js kad izvalci podatke uvek krece od nule umesto od jedinice

    const Pitanje1 = async () => { 
      const response = await axios.post(konekcija+"pitanje", { id_meca: id_meca });
      const prikazPitanja = <b>{response.data[podaciKvizBrPitanja[0].br_pitanja-1].pitanje}</b>;
      setPitanje(prikazPitanja); 
      setPodaciKviz(() => [ {id_pitanja: response.data[podaciKvizBrPitanja[0].br_pitanja-1].id}]);    //sacuvamo vrednost ID-a u contextu da bi mogli da ga pozovemo u odgovorima
    }

    useEffect(() => { 
        Pitanje1();  
        socket.emit("ucitao_pitanje", { kanal });
    },[podaciKvizBrPitanja[0].br_pitanja]); 

    return(
        <Row className="w-100">     
            <Col md={1} lg={1}  className="pitanjeOkolo">
              
            </Col>
            <Col sm={12} md={10} lg={10}  className="pitanjeDiv">
                {pitanje}
            </Col>
            <Col md={1} lg={1} className="pitanjeOkolo">
            
            </Col>
        </Row>
    )
}
export default Pitanje;