import '../../css/index.scss';
import { useState, useEffect, useContext, useRef, useMemo } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Button} from 'react-bootstrap';
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import { PocModalContext } from '../../../globalne/PocModalContext';
import { socketContext } from '../../../globalne/Socket';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NoNo from '../../../slike/no_no.gif';
import Cekam from '../../../slike/cekam.gif';
import ActiveRoom from '../../../slike/active_room.gif';

function Online(props:any) {
   // alert(props.igrac)
    const konekcija = useContext(konekcijaContext);
    const socket = useContext(socketContext);
    const MySwal = withReactContent (Swal);
    const navigate = useNavigate();
    const {podaci, setPodaci} = useContext<any>(PocModalContext);
    const [kanal1, setKanal1] = useState<any>();
    //const [online_sobe, setOnline_sobe] = useState<any>(); 
    const [igrac, setIgrac] = useState<any>(props.igrac);
    const [igrac2, setIgrac2] = useState<any>(props.igrac);
    const [br_slicica, setBr_slicica] = useState<any>(/*16*/props.br_slicica);  //broj slicica domacina tj prvog igraca...ovo uvek uzima od svog browsera
    const [prikazSoba, setPrikazSoba] = useState<any>();
    let id_match1:any = null;

    const kodRef = useRef<any>();
    const kreirajDugmeRef = useRef<any>();
    const startRef = useRef<any>();
    const cookies = new Cookies();

    const valueKod = useMemo(() => ({
        kanal1,setKanal1
    }), [kanal1,setKanal1]);


    /*OVO ODMAH SA UCITAVANJEM STRANICE PRIKAZUJE SVE ONLINE SOBE AKTIVNE...I NA DODAVANJE NOVE SOBE U MEDJUVREMENU OVO SE AZURIRA*/
    useEffect(() => {

        function makeid(length:number) {
            var result           = '';
            var characters       = '0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() *  charactersLength));
            }
            return result;
        }     
        var unique_kanal =  makeid(4);
        setKanal1(unique_kanal);
      

        startRef.current.disabled = true;

        socket.emit("zahtev_za_sve_aktivne_sobe");
        socket.on("sve_aktivne_sobe", async (data:any) => {  

            // const response = await axios.post(konekcija+"ime_igraca", { igrac: data.id_igraca });
            // let ime_igraca = response.data[0].username;
            let vr_igre = data.vr_igre;
            if(data != ""){

                let brojac:number = 0;
                const onlineRoomHTML = data.res.map( (item :any, index: any) => {  
                   
                    if(item != unique_kanal){ //moj kreiran mec nikad ne prikazuj meni!!!!
                        brojac = brojac + 1;
                        return (  
                            <div className="act_sobe" key={index}><b>{brojac}.</b><img src={ActiveRoom} alt="ActiveRoom" width="30px" height="30px"/> <b>{item}</b> <Button className='pridruziSe' variant="success" size="sm" onClick={(e) => {pristupiMecu(item,vr_igre);} }>Pridruži se</Button><br/></div>
                        )
                    }
                                                             
                });
                setPrikazSoba(onlineRoomHTML);
            }else{
                let nemaOnlMeca = <b className='nemaOnlMeca'>Nema online mečeva</b>;
                setPrikazSoba(nemaOnlMeca); 
            }
        });
    },[socket]);
    /*OVO ODMAH SA UCITAVANJEM STRANICE PRIKAZUJE SVE ONLINE SOBE AKTIVNE...I NA DODAVANJE NOVE SOBE U MEDJUVREMENU OVO SE AZURIRA*/


    useEffect(() => {  
        socket.on("primanje_id_drugog_igraca", async (data:any) => {   
            setIgrac2(data.igrac)
            let mojKanal = (document.getElementById("kodRef") as HTMLInputElement).innerText;
            if(mojKanal != data.kanal1){
                startRef.current.disabled = false;  //prvom igracu enable start dugme a drugom ostaje disable
                MySwal.close(); 
            }
        });

        socket.on("provera_soba", async (data:any) => {   
                     
            if(data.message == "ima"){ 
                startRef.current.disabled = true;
                //startRef.current.style.backgroundColor  = 'green';
                kreirajDugmeRef.current.disabled = true;
               
                MySwal.fire({
                    title: 'Čeka se drugi igrač da startuje meč',
                    showCancelButton: false,
                    showConfirmButton: false,
                    html: "<img src='" + Cekam + "' style='width:150px;'>"
                })
            }else if(data.message == "nema"){  alert('nema sobe');
                MySwal.fire({
                    title: 'Izabrani meč ne postoji!',
                    timer: 5000,
                    showCancelButton: false,
                    showConfirmButton: false,
                    html: "<img src='" + NoNo + "' style='width:150px;'>"
                })  
            }
        });

 
},[socket]); 




    /*OVIM KREIRAMO MI MEC I CEKAMO DRUGOG IGRACA + SVIMA OSTALIMA JAVIMO I PRIKAZEMO DA SMO KREIRALI MEC...DA MOGU DA SE PRIDRUZE*/
    const kreiraj_mec = () => {
        setIgrac(props.igrac);
        //let igrac = props.igrac;
        socket.emit("join_room", kanal1);
        kreirajDugmeRef.current.disabled = true;
        startRef.current.disabled = true;
        if(props.odbIgre == 1){
            cookies.set('memoryGamePlayer', "true", { path: 'glavna' }); //kreiranje cookie koji vazi samo na stranici glavna...i koji se brise kad se zatvori browser
            //kada se gleda iz recimo dva ista browsera-chroma onda se ovi cookie gaze i ostaje samo validan onaj false iz pristupi mecu...
            //ovo se nece desavati online kada budu dva razlicita uredjaja
        }else if(props.odbIgre == 2){
            cookies.set('kvizGamePlayer', "true", { path: 'kviz' });
        }
        
        MySwal.fire({
        title: 'Čeka se drugi igrač',
        //text: '',
        //icon: 'info',
        //timer: 5000,
        showCancelButton: false,
        showConfirmButton: false,
        html: "<img src='" + Cekam + "' style='width:150px;'>"
        })
        let vr_igre = props.odbIgre;
        socket.emit("zahtev_za_sve_aktivne_sobe", vr_igre/*, igrac*/);
    }
    /*OVIM KREIRAMO MI MEC I CEKAMO DRUGOG IGRACA + SVIMA OSTALIMA JAVIMO I PRIKAZEMO DA SMO KREIRALI MEC...DA MOGU DA SE PRIDRUZE*/ 
    
    /*OPCIJA DA SE PRIKLJUCIMO NEKOM VEC KREIRANOM MECU IZ LISTE*/
    const pristupiMecu = (prist_mecu:any, vr_igre:any) => { 
        let kanal1 = (document.getElementById("kodRef") as HTMLInputElement).innerText;
        let igrac = (document.getElementById("igr") as HTMLInputElement).value;//zbog kasnijeg kreiranja funkcije jedino ovako mozemo da pristupimo ovm podatku...uzimanje id-a iz parent komponente
       // let kanal1 = kodRef.current.innerText;//zbog kasnijeg kreiranja funkcije jedino ovako mozemo da pristupimo ovm podatku...ovaj kanal1 je samo u ovoj fcj vidljiv

       if(vr_igre == 1){ 
        cookies.set('memoryGamePlayer', "false", { path: 'glavna' });
        }else if(vr_igre == 2){ 
            cookies.set('kvizGamePlayer', "false", { path: 'kviz' });   
        }
        socket.emit("pristupi_room", prist_mecu, vr_igre);
        socket.emit("slanje_id_drugog_igraca", {igrac:igrac, prist_mecu, kanal1}); //ovo salje drugi igrac svoj id     
    }
    /*OPCIJA DA SE PRIKLJUCIMO NEKOM VEC KREIRANOM MECU IZ LISTE*/





    const startMeca = () => {
        if(props.odbIgre == 1){ //ako je igra memorije
                let br_slicica = (document.getElementById("br_sl") as HTMLInputElement).value;
                fetch(konekcija+'dodavanje_meca', {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        igrac:igrac,igrac2:igrac2,br_slicica:br_slicica
                    })
                
                }).then(res => res.json()).then(data => {
                    if(data !== ""){
                        let id_meca = data.id;  
                        let kanal = kanal1;                    
                        id_match1 = data.id;
                        /*niz css klasa - izvucemo jednu random i tu posaljemo komponenti Glavna.tsx a ona dalje je prosledjuje na Kartica.tsx i setujemo u fcj getFrontAndBackFromCard isto tu klasu*/      
                        var frontImges = ['front','front1','front2','front3','front4','front5','front6','front7'];
                        var frontImg = frontImges[Math.floor(Math.random() * frontImges.length)];               
                        socket.emit("send_message", {id_meca, kanal, igrac, igrac2, br_slicica, frontImg, vrstaIgre: props.odbIgre}); //kad kreiramo mec onda ga saljemo svima...tj i jednom i drugom igracu                      
                        Sve_slike();                                       
                }})
                socket.emit("zahtev_za_sve_aktivne_sobe");
        }else if(props.odbIgre == 2){ //ako je kviz
                let kanal = kanal1;
                fetch(konekcija+'kviz_dodavanje_meca', {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        igrac:igrac,igrac2:igrac2
                    })
                
                }).then(res => res.json()).then(data => {
                    if(data !== ""){
                        let id_mec_kviza = data.id_meca;
                        socket.emit("send_message", { id_mec_kviza, kanal, igrac, igrac2, vrstaIgre: props.odbIgre});                                     
                }})
                socket.emit("zahtev_za_sve_aktivne_sobe");
        }
         
}


        const Sve_slike = async () => {    
            let br_slicica = (document.getElementById("br_sl") as HTMLInputElement).value;             
            const response = await axios.post(konekcija+"pregled_slika1", { br_slicica: br_slicica });
            const slika = [...response.data, ...response.data];
            slika.sort((_:any) => Math.random() - 0.5);
                slika.map( (slika :any) => {
                axios.post(konekcija+"dodavanje_mec_kartica", { id_meca: id_match1, id_kartice: slika.id  });       
            });                   
        }


        useEffect(() => {  
            socket.on("receive_message", (data:any) => { 
                
                if(data.vrstaIgre == 1){ //ako je igra memorije
                    setPodaci((prevPodaci:any) => [  /*...prevPodaci,*/ {da_li_je_online: "online je"}]);  
                    MySwal.close(); //zatvaramo alert "ceka se mec" koji je aktiviran iz provera_soba 
                    navigate("/glavna",{state: {igrac:data.igrac,igrac2:data.igrac2,br_slicica:data.br_slicica,id_meca:data.id_meca,kanal:data.kanal,frontImg:data.frontImg} }); //ovo i jedneog i drugog igraca prebacuje u sledecu komponentu
                }else if(data.vrstaIgre == 2){  
                    setPodaci((prevPodaci:any) => [  /*...prevPodaci,*/ {da_li_je_online: "online je"}]);  
                    MySwal.close(); //zatvaramo alert "ceka se mec" koji je aktiviran iz provera_soba 
                
                    //socket.emit("startuj_kviz", {kanal:data.kanal, id_meca:data.id_mec_kviza});
                   
                    
                    navigate("/kviz",{state: {id_meca:data.id_mec_kviza,igrac:data.igrac,igrac2:data.igrac2,kanal:data.kanal} }); //ovo i jedneog i drugog igraca prebacuje u sledecu komponentu
                }    
        });
      },[socket]); 


    return(
        <Col sm={3} md={4} lg={4}  className="online_podesavanje box1">
            <h4>Moj online id:  <b ref={kodRef} id="kodRef">{kanal1}</b> </h4>
            
            
            <Button className="form-control select-kanali" ref={kreirajDugmeRef} onClick={kreiraj_mec}>Kreiraj online meč</Button> 
            <br/> <br/>
            <Button variant="danger" ref={startRef} className="form-control select-kanali" onClick={ (event) => { startMeca(); } }>
            Start
            </Button>
            <br/><br/>
            <div className="allOnline">{prikazSoba}</div>
        </Col> 
    )
}

export default Online;