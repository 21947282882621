import '../css/index.scss';
import { useState, useEffect, useContext, useMemo } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import {  Row, Col} from 'react-bootstrap';
import User from '../../../slike/user.png';
import { KvizContext } from '../../../globalne/KvizContext';
import { KvizContextBrPitanja } from '../../../globalne/KvizContextBrPitanja';
import { KvizContextSkala } from '../../../globalne/KvizContextSkala';
import axios from "axios";
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import { useLocation} from 'react-router-dom';
import Cookies from 'universal-cookie';
import { socketContext } from '../../../globalne/Socket';
import Progress from 'rsuite/Progress';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

/*slike alert pobedio-izgubio-nereseno*/
import ImgPobedio from '../../../slike/pobeda.gif';
import ImgWinBoy from '../../../slike/boywin.gif';
import ImgWinGirl from '../../../slike/winnergirl.gif';
import ImgIzgubio from '../../../slike/izgubio.gif';
import ImgAngryBoy from '../../../slike/angryboy.gif';
import ImgAngryGirl from '../../../slike/angrygirl.gif';
import ImgNereseno from '../../../slike/nereseno.gif';
import ImgNeresenoBoy from '../../../slike/boynereseno.gif';
import ImgNeresenoGirl from '../../../slike/neresenogirl.gif';
//import ImgNapustioMec from '../../../slike/napustio_mec.gif';
/*slike alert pobedio-izgubio-nereseno*/


type Tipovi_podataka = {
    igrac: number;
    igrac2: number;
    kanal:any;
    id_meca:number;
}

function IgraciVreme(props:any) {

    const socket = useContext(socketContext);
    const cookies = new Cookies();
    const cookiesIgrac = cookies.get('kvizGamePlayer');
    const konekcija = useContext(konekcijaContext);
    const dolaz_param = useLocation();
    const podaci_dolazni = dolaz_param.state as Tipovi_podataka;
    const {podaciKviz, setPodaciKviz} = useContext<any>(KvizContext);
    const {podaciKvizBrPitanja, setPodaciKvizBrPitanja} = useContext<any>(KvizContextBrPitanja);
    const {skala, setSkala} = useContext<any>(KvizContextSkala);
    const [ime_igraca, setIme_igraca] = useState<any>();
    const [ime_igraca2, setIme_igraca2] = useState<any>();
    const [secs, setSecs] = useState<any>(10);
    const [starter, setStarter] = useState<any>(false);
    const [vreme, setVreme] = useState<any>(100);
    //const [vreme1, setVreme1] = useState<any>(0);
    const [igracJedanBrPoena, setIgracJedanBrPoena] = useState<any>(0);
    const [igracDvaBrPoena, setIgracDvaBrPoena] = useState<any>(0);
    const MySwal = withReactContent (Swal); //sweetalert2
    var timer :any;
    var igrac = podaci_dolazni.igrac; 
    var igrac2 = podaci_dolazni.igrac2;
    var kanal = podaci_dolazni.kanal; 
    var id_meca = podaci_dolazni.id_meca; 

    // if(socket.connected){
    //     alert('konektovan sam')
    // }

    const value = useMemo(() => ({
        starter, setStarter
    }), [starter, setStarter]);


    const Ime_igraca = async () => {
        const response = await axios.post(konekcija+"ime_igraca", { igrac: igrac });
        setIme_igraca(response.data[0].username);
    }

    const Ime_igraca1 = async () => {
        if(typeof igrac2 != 'undefined'){ //ovim uslovom kazemo da ovo unutar ovog if-a radi samo kad su dva igraca aktivna ... bilo lokalno bilo online
            const response = await axios.post(konekcija+"ime_igraca", { igrac: igrac2 });
            setIme_igraca2(response.data[0].username);
        }
    }

    useEffect(() => { 
        Ime_igraca();
        Ime_igraca1();
        },[]);

        // useEffect(() => { 
        //         if(cookiesIgrac == "true"){ //da ne bih bilo dva zahteva za start meca uvek prvi igrac salje zahtev
        //             socket.emit("ucitao_prvi", { kanal });
        //         }
        //         else if(cookiesIgrac == "false"){
        //             socket.emit("ucitao_drugi", { kanal });
        //         }
            
        //     },[]);



        socket.on("startuj", (data:any) => {   //alert(value.starter)
            setStarter(true);
        console.log('startovano')
            });
        
    useEffect(() => {  
            
    //    if(cookiesIgrac == "true"){ //da ne bih bilo dva zahteva za start meca uvek prvi igrac salje zahtev
    //         socket.emit("startuj_kviz", { kanal, id_meca });
    //    }
  
        if(value.starter == true){  //ovim startujemo tajmer
            timer = setInterval(() =>{
                setSecs(secs - 1);
                if(secs === 10){
                    setPodaciKviz(() => [ {vreme: secs}]); 
                    setVreme(100);
                }else if(secs === 9){
                    setVreme(90);
                    setPodaciKviz(() => [ {vreme: secs}]);                   
                }else if(secs === 8){
                    setVreme(80);
                    setPodaciKviz(() => [ {vreme: secs}]); 
                }else if(secs === 7){
                    setVreme(70);
                    setPodaciKviz(() => [ {vreme: secs}]); 
                }else if(secs === 6){
                    setVreme(60);
                    setPodaciKviz(() => [ {vreme: secs}]); 
                }else if(secs === 5){
                    setVreme(50);
                    setPodaciKviz(() => [ {vreme: secs}]); 
                }else if(secs === 4){
                    setVreme(40);
                    setPodaciKviz(() => [ {vreme: secs}]); 
                }else if(secs === 3){
                     setVreme(30);
                     setPodaciKviz(() => [ {vreme: secs}]); 
                }else if(secs === 2){
                     setVreme(20);
                     setPodaciKviz(() => [ {vreme: secs}]); 
                }else if(secs === 1){
                     setVreme(10);
                     setPodaciKviz(() => [ {vreme: secs}]); 
                }else if(secs === 0){
                    setVreme(0);
                    setStarter(false);
                    ProveraOdgovora();
                    setPodaciKviz(() => [ {vreme: secs}]); 
                    return;
                }            
            },1000)
            return () => clearInterval(timer);
        }else{                      
            setSecs(10); //restart tajmera - sekunde
        } 
        
    });



    useEffect(() => { 
       
    },[starter,setStarter]);

    useEffect(() => { 
       
    },[igracJedanBrPoena, igracDvaBrPoena]); 



    const ProveraOdgovora = async () => {
        //setStarter(false);
        socket.emit("restartuj_odgovore", {kanal});
        const response = await axios.post(konekcija+"pitanje", { id_meca: id_meca });         
        let id_pitanja = response.data[podaciKvizBrPitanja[0].br_pitanja-1].id_pitanja;

        const response1 = await axios.post(konekcija+"provera_odgovora", { id_pitanja: id_pitanja }); 
        let br_pitanja = podaciKvizBrPitanja[0].br_pitanja;

        if(response1.data.length == 0){ //ako niko nije odgovorio tacno samo prebacujemo na sledece pitanje
               
                        if(br_pitanja == 10){
                                setStarter(false);
                                if(cookiesIgrac == "true"){ //da ne bih bilo dva zahteva za proveru uvek prvi igrac vrsi proveru ko je pobedio
                                    var igrJedan = igracJedanBrPoena;
                                    var igrDva = igracDvaBrPoena;
                                    socket.emit("ko_je_pobedio", { kanal, igrJedan, igrDva, ime_igraca, ime_igraca2 });
                                }
                        }else{  
                                //restart vremena vracanje u prvobitni polozaj i startovanje
                                clearInterval(timer);
                                setVreme(100);
                                setSecs(10);
                                setStarter(true);
                                //restart vremena vracanje u prvobitni polozaj i startovanje                    
                        } 
        }else{//igrac koji je odgovorio tacno   -    ovde njemu dodeljujemo poene i prebacujemo na sledece pitanje
           
                        if(br_pitanja == 10){ //zaustavljamo igru sa tacnim odgovorom........the end
                            setStarter(false);                                   //zaustavimo sat (tajmer)
                            
                            var igrJedan = igracJedanBrPoena;
                            var igrDva = igracDvaBrPoena;
                            let igrac_tacno_odg = response1.data[0].id_igraca;   //id igraca koji je odgovorio tacno
                            if(igrac_tacno_odg == igrac){                        //ako je to prvi igraca
                                setIgracJedanBrPoena(igracJedanBrPoena + 1);     //prvom igracu dodeli poen
                                setSkala(() => [ {igrac: "crveni"}]);            //setujemo context 
                                igrJedan = igracJedanBrPoena + 1;                //dodeli i nekoj promenljivoj 
                            }
                            else if(igrac_tacno_odg == igrac2){                  //ako je drugi igrac
                                setIgracDvaBrPoena(igracDvaBrPoena + 1);         //drugom igracu dodeli poen
                                setSkala(() => [ {igrac: "plavi"}]);
                                igrDva = igracDvaBrPoena + 1;                    //dodeli i nekoj promenljivoj 
                            }
                            
                            
                            if(cookiesIgrac == "true"){ //da ne bih bilo dva zahteva za proveru uvek prvi igrac vrsi proveru ko je pobedio                              
                                socket.emit("ko_je_pobedio", { kanal, igrJedan, igrDva, ime_igraca, ime_igraca2 });
                            }
                            

                        }else{  
                            let igrac_tacno_odg = response1.data[0].id_igraca;  //id igraca koji je odgovorio tacno
                            if(igrac_tacno_odg == igrac){                       //ako je to prvi igraca
                                setIgracJedanBrPoena(igracJedanBrPoena + 1);    //prvom igracu dodeli poen
                                setSkala(() => [ {igrac: "crveni"}]);           //setujemo context
                            }
                            else if(igrac_tacno_odg == igrac2){                 //ako je drugi igrac
                                setIgracDvaBrPoena(igracDvaBrPoena + 1);        //drugom igracu dodeli poen
                                setSkala(() => [ {igrac: "plavi"}]);            //setujemo context
                            }

                            //restart vremena vracanje u prvobitni polozaj i startovanje
                            clearInterval(timer);
                            setVreme(100);
                            setSecs(10);
                            setStarter(true);
                            //restart vremena vracanje u prvobitni polozaj i startovanje      
                            
                            //console.log('igrac jedan '+igrac+' igrac dva '+igrac2+' tacno odg '+igrac_tacno_odg)
                        }
                 
        }

        if(podaciKvizBrPitanja[0].br_pitanja < 10){
            br_pitanja = podaciKvizBrPitanja[0].br_pitanja + 1;    
            setPodaciKvizBrPitanja(() => [ {br_pitanja: br_pitanja}]); //setujemo context ovim menjamo pitanje            
        }       
    }


  
    useEffect(() => { 
       
    },[starter]); 


    useEffect(() => {  
        socket.on("pobedio_je", (data:any) => {  
            if(data.igrJedan > data.igrDva){ 
                if(cookiesIgrac == "true"){
                        var winnerImg = [ImgWinBoy,ImgWinGirl,ImgPobedio];
                        var win = winnerImg[Math.floor(Math.random() * winnerImg.length)];
                        MySwal.fire({
                            title: 'Pobedio si '+data.ime_igraca,
                            //text: '',
                            //icon: 'info',
                            timer: 5000,
                            showCancelButton: false,
                            showConfirmButton: false,
                            html: "<img src='" + win + "' style='width:150px;'>"
                        })
                    
                }else{
                        var losersImg = [ImgAngryBoy,ImgAngryGirl,ImgIzgubio];
                        var loser = losersImg[Math.floor(Math.random() * losersImg.length)];
                        MySwal.fire({
                            title: 'Izgubio si '+data.ime_igraca2,
                            //text: '',
                            //icon: 'info',
                            timer: 5000,
                            showCancelButton: false,
                            showConfirmButton: false,
                            html: "<img src='" + loser + "' style='width:150px;'>"
                        })
                }            
            }else if(data.igrJedan < data.igrDva){
                if(cookiesIgrac == "false"){
                        var winnerImg = [ImgWinBoy,ImgWinGirl,ImgPobedio];
                        var win = winnerImg[Math.floor(Math.random() * winnerImg.length)];
                        MySwal.fire({
                            title: 'Pobedio si '+data.ime_igraca2,
                            //text: '',
                            //icon: 'info',
                            timer: 5000,
                            showCancelButton: false,
                            showConfirmButton: false,
                            html: "<img src='" + win + "' style='width:150px;'>"
                        })
                }else{
                        var losersImg = [ImgAngryBoy,ImgAngryGirl,ImgIzgubio];
                        var loser = losersImg[Math.floor(Math.random() * losersImg.length)];
                        MySwal.fire({
                            title: 'Izgubio si '+data.ime_igraca,
                            //text: '',
                            //icon: 'info',
                            timer: 5000,
                            showCancelButton: false,
                            showConfirmButton: false,
                            html: "<img src='" + loser + "' style='width:150px;'>"
                        })
                }
            }else if(data.igrJedan == data.igrDva){
                        var neresenoImg = [ImgNeresenoBoy,ImgNeresenoGirl,ImgNereseno];
                        var nerese = neresenoImg[Math.floor(Math.random() * neresenoImg.length)];
                        MySwal.fire({
                            title: 'Nerešeno',
                            //icon: 'info',
                            timer: 5000,
                            showCancelButton: false,
                            showConfirmButton: false,
                            html: "<img src='" + nerese + "' style='width:150px;'>"
                        })
            }          
        });
    },[socket]);


    return(
            <Row className="w-100">     
                <Col xs={5} sm={5} md={5} lg={5} className="userLevi userLeviTop">
                    <img className=" d-block userSlike" src={User} alt="winer"/>
                    <div className="imeUserCrveni"><h4>{ime_igraca}</h4></div>
                    <div className="imeUserCrveni"><b>{igracJedanBrPoena}</b> </div>    
                </Col>

                <Col xs={2} sm={2} md={2} lg={2}  className="">
                    <Progress.Line vertical percent={vreme} /*status="active"*/ showInfo={false} /*strokeColor="BOJA"*/ style={{ height: '100%' }}/>
                </Col>

                <Col xs={5} sm={5} md={5} lg={5} className="userLevi userDesniTop">
                    <img className=" d-block userSlike" src={User} alt="winer"/>
                    <div className="imeUserPlavi"><h4>{ime_igraca2}</h4></div>
                    <div className="imeUserPlavi"><b>{igracDvaBrPoena}</b> </div> 
                </Col>
            </Row>
    )
}

export default IgraciVreme;