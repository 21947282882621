import {  createContext } from "react";
import * as io from 'socket.io-client';

//export const socket = io.connect('https://socket.ginder.in.rs');
export const socket = io.connect('http://localhost:3001');


export const socketContext = createContext<any>(null);


